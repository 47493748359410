import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AssessmentDetails from '../components/templates/AssessmentDetails';
import Breadcrumbs, { BreadcrumbItem } from '../components/molecules/Breadcrumbs';
import api from '../api';
import { Course, Module, Assessment } from '../types/types';

const AssessmentPage: React.FC = () => {
  const { courseId, moduleId } = useParams<{ courseId: string; moduleId: string }>();
  const [assessment, setAssessment] = useState<Assessment | null>(null);
  const [course, setCourse] = useState<Course | null>(null);
  const [module, setModule] = useState<Module | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (courseId && moduleId) {
      setLoading(true);
      api.get<Course>(`/courses/${courseId}`)
        .then((response) => {
          const courseData = response.data;
          setCourse(courseData);
          const moduleData = courseData.modules.find((m: Module) => m.id === moduleId);
          if (moduleData) {
            setModule(moduleData);
            if (moduleData.assessment) {
              setAssessment(moduleData.assessment);
            } else {
              setError(`Assessment not found for module ${moduleId}`);
            }
          } else {
            setError(`Module ${moduleId} not found in course ${courseId}`);
          }
        })
        .catch(error => {
          console.error('Error fetching course:', error);
          setError(`Failed to load course data. Error: ${error.message}`);
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      setError('Invalid course or module ID.');
      setLoading(false);
    }
  }, [courseId, moduleId]);

  const breadcrumbs: BreadcrumbItem[] = [
    { name: 'Courses', href: '/' },
    ...(course ? [{ name: course.title, href: `/course/${courseId}` }] : []),
    ...(module ? [{ name: module.moduleName, href: `/module/${courseId}/${moduleId}` }] : []),
    ...(assessment ? [{ name: 'Assessment', href: `/assessment/${courseId}/${moduleId}` }] : []),
  ];

  return (
    <div className="mx-auto p-4">
      <Breadcrumbs items={breadcrumbs} />
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {!loading && !error && assessment && <AssessmentDetails assessment={assessment} />}
      {!loading && !error && !assessment && <div>No assessment data available.</div>}
    </div>
  );
};

export default AssessmentPage;
