import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../api';
import Breadcrumbs, { BreadcrumbItem } from '../components/molecules/Breadcrumbs';
import { Course } from '../types/types';


const CoursePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<Course | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get<Course>(`/courses/${id}`)
        .then((response) => {
          setCourse(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching course:', error);
          setError(`Failed to load course data. Error: ${error.message}`);
          setLoading(false);
        });
    } else {
      setError('Invalid course ID');
      setLoading(false);
    }
  }, [id]);

  const breadcrumbs: BreadcrumbItem[] = [
    { name: 'Courses', href: '/' },
    ...(course ? [{ name: course.title, href: `/course/${id}` }] : []),
  ];

  return (
    <div className="mx-auto p-4">
      <Breadcrumbs items={breadcrumbs} />
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {!loading && !error && course && (
        <div>
          <h1 className="text-2xl font-bold mb-4">{course.title}</h1>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Description/Objective</td>
                <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">{course.objectiveDescription}</td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Length</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{course.length} days</td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Lessons per day</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{course.lessonsPerDay}</td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Modules</td>
                <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">
                  <ul className="list-disc pl-5">
                    {course.modules.map((module) => (
                      <li key={module.id}>
                        <Link 
                          to={`/module/${course.id}/${module.id}`}
                          className="text-blue-600 hover:text-blue-800 underline"
                        >
                          {module.moduleName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {!loading && !error && !course && <div>No course data available.</div>}
    </div>
  );
};

export default CoursePage;
