import React, { useState, useEffect } from 'react';
import SiteLayout from '../components/templates/SiteLayout';
import DashboardCards from '../components/templates/DashboardCards';
import api from '../api';
import { Course } from '../types/types';

const DashboardPage: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    api.get<Course[]>('/courses')
      .then((response) => {
        setCourses(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching courses:', error);
        setError('Failed to load courses. Please try again later.');
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="px-4 py-6 sm:px-0">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        {loading && <p>Loading courses...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <>
            <p className="mt-2 text-sm text-gray-700">
              You have access to {courses.length} course{courses.length !== 1 ? 's' : ''}.
            </p>
            <div className="mt-6">
              <DashboardCards courses={courses} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DashboardPage;
