import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QuizDetails from '../components/templates/QuizDetails';
import Breadcrumbs, { BreadcrumbItem } from '../components/molecules/Breadcrumbs';
import api from '../api';
import { Course, Module, Quiz } from '../types/types';

const QuizPage: React.FC = () => {
  const { courseId, moduleId } = useParams<{ courseId: string; moduleId: string }>();
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const [course, setCourse] = useState<Course | null>(null);
  const [module, setModule] = useState<Module | null>(null);
  const [moduleIndex, setModuleIndex] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (courseId && moduleId) {
      setLoading(true);
      const url = `/courses/${courseId}`;
      console.log('Fetching course data from:', url);
      api.get<Course>(url)
        .then((response) => {
          console.log('Course data received:', response.data);
          const courseData = response.data;
          setCourse(courseData);
          const moduleData = courseData.modules.find(m => m.id === moduleId);
          if (moduleData) {
            setModule(moduleData);
            setModuleIndex(courseData.modules.indexOf(moduleData));
            if (moduleData.quiz) {
              setQuiz(moduleData.quiz);
            } else {
              setError(`Quiz not found for module ${moduleId} in course ${courseId}`);
            }
          } else {
            setError(`Module with ID ${moduleId} not found in course ${courseId}`);
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching course:', error);
          setError(`Failed to load course data. Error: ${error.message}`);
          setLoading(false);
        });
    } else {
      setError('Invalid course or module ID.');
      setLoading(false);
    }
  }, [courseId, moduleId]);

  const breadcrumbs: BreadcrumbItem[] = [
    { name: 'Courses', href: '/' },
    ...(course ? [{ name: course.title, href: `/course/${courseId}` }] : []),
    ...(module && moduleId !== null ? [{ name: module.moduleName, href: `/module/${courseId}/${moduleId}` }] : []),
    ...(quiz ? [{ name: 'Quiz', href: `/course/${courseId}/module/${moduleId}/quiz` }] : []),
  ];

  return (
    <div className="mx-auto p-4">
      <Breadcrumbs items={breadcrumbs} />
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {!loading && !error && quiz && (
        <>
          <QuizDetails quiz={quiz} />
        </>
      )}
      {!loading && !error && !quiz && <div>No quiz data available.</div>}
      <div className="mt-4 text-sm text-gray-500">
        Debug: Course ID: {courseId}, Module ID: {moduleId}, Module ID: {moduleId !== null ? moduleId : 'Not available'}
      </div>
    </div>
  );
};

export default QuizPage;
