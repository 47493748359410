import React from 'react';
import { Assessment } from '../../types/types';

interface AssessmentDetailsProps {
  assessment: Assessment;
}

const AssessmentDetails: React.FC<AssessmentDetailsProps> = ({ assessment }) => {
  const totalPoints = assessment.rubric.reduce((sum, item) => sum + item.totalPoints, 0);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{assessment.title}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Required Passing Score: {assessment.requiredPassing} / {totalPoints}
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assessment.description}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Deliverables</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assessment.deliverables}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Rubric</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {assessment.rubric.map((item, index) => (
                <div key={index} className="mb-4">
                  <h4 className="font-medium">{item.title} (Total Points: {item.totalPoints})</h4>
                  <ul className="list-disc pl-5 space-y-1">
                    {item.gradingCriteria.map((criterion, criterionIndex) => (
                      <li key={criterionIndex}>
                        {criterion.point} point{criterion.point !== 1 ? 's' : ''}: {criterion.description}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default AssessmentDetails;
