import React, { useState, useEffect } from 'react';
import api from '../../api';

interface Course {
  id: number;
  title: string;
  descriptionObjective: string;
  length: number;
  lessonsPerDay: number;
  modules: any[];
}

interface CourseListProps {
  onCourseSelect: (courseId: number) => void;
}

const CourseList: React.FC<CourseListProps> = ({ onCourseSelect }) => {
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    api.get<Course[]>('/courses')
      .then(response => setCourses(response.data))
      .catch(error => console.error('Error fetching courses:', error));
  }, []);

  return (
    <div>
      <h4 className="font-semibold text-md mb-2">Your Courses</h4>
      <ul className="space-y-2">
        {courses.map(course => (
          <li 
            key={course.id} 
            className="text-sm text-gray-700 hover:bg-gray-100 rounded-md p-2 cursor-pointer"
            onClick={() => onCourseSelect(course.id)}
          >
            {course.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CourseList;
