import React from 'react';
import { Outlet } from 'react-router-dom';
import SiteLayout from './components/templates/SiteLayout';

function App() {
  return (
    <SiteLayout>
      <Outlet />
    </SiteLayout>
  );
}

export default App;
