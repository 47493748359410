import React from 'react';

interface Question {
  id: string;
  question: string;
  options: string[];
  correctAnswer: number;
}

interface Quiz {
  id: string;
  title: string;
  questions: Question[];
  passingScore: number;
}

interface QuizDetailsProps {
  quiz: Quiz;
}

const QuizDetails: React.FC<QuizDetailsProps> = ({ quiz }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{quiz.title}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Passing Score: {quiz.passingScore} / {quiz.questions.length}
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          {quiz.questions.map((question, index) => (
            <div key={question.id} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Question {index + 1}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <p>{question.question}</p>
                <ul className="mt-2 list-disc pl-5 space-y-1">
                  {question.options.map((option, optionIndex) => (
                    <li key={optionIndex} className={optionIndex === question.correctAnswer ? "font-semibold" : ""}>
                      {option}
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default QuizDetails;
