import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseList from './CourseList';
import CreateCourseModal from './CreateCourseModal';

interface FlyoutMenuProps {
  buttonText: string;
  navbarHeight: number;
}

const FlyoutMenu: React.FC<FlyoutMenuProps> = ({ buttonText, navbarHeight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCourseSelect = (courseId: number) => {
    setIsOpen(false);
    navigate(`/course/${courseId}`);
  };

  const handleCreateCourse = (newCourse) => {
    console.log('New course created:', newCourse);
    setIsModalOpen(false);
    // Optionally, you can navigate to the new course page here
    // navigate(`/course/${newCourse.id}`);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
      >
        {buttonText}
      </button>
      <div
        ref={menuRef}
        className={`fixed left-0 w-64 bg-white shadow-lg rounded-r-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
        style={{ 
          top: `${navbarHeight}px`,
          height: `calc(100vh - ${navbarHeight}px)`
        }}
      >
        <div className="h-full flex flex-col overflow-y-auto p-4">
          <button
            onClick={() => setIsOpen(false)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h3 className="font-bold text-lg mb-4">Courses Menu</h3>
          <button
            onClick={() => setIsModalOpen(true)}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md mb-4"
          >
            Create Course
          </button>
          <CourseList onCourseSelect={handleCourseSelect} />
        </div>
      </div>
      <CreateCourseModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onCourseCreated={handleCreateCourse} />
    </div>
  );
};

export default FlyoutMenu;
