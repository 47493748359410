import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import './index.css';
import HomePage from './pages/HomePage';
import CoursePage from './pages/CoursePage';
import ModulePage from './pages/ModulePage';
import LessonPage from './pages/LessonPage';
import QuizPage from './pages/QuizPage';
import AssessmentPage from './pages/AssessmentPage';
import ExercisePage from './pages/ExercisePage';
import DashboardPage from './pages/DashboardPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'course/:id', element: <CoursePage /> },
      { path: 'module/:courseId/:moduleId/', element: <ModulePage /> },
      { path: 'lesson/:courseId/:moduleId/:lessonId', element: <LessonPage /> },
      { path: 'quiz/:courseId/:moduleId', element: <QuizPage /> },
      { path: 'assessment/:courseId/:moduleId', element: <AssessmentPage /> },
      { path: 'exercise/:courseId/:moduleId/:exerciseId', element: <ExercisePage /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
