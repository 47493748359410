import React from 'react';
import { Link } from 'react-router-dom';
import { Module, Lesson, Assessment, Quiz, Exercise } from '../../types/types';


interface ModuleDetailsProps {
  module: Module;
  courseId: string;
}

const ModuleDetails: React.FC<ModuleDetailsProps> = ({ module, courseId }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{module.moduleName}</h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Lessons</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                {module.lessons && module.lessons.map((lesson) => (
                  <li key={lesson.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <Link 
                        to={`/lesson/${courseId}/${module.id}/${lesson.id}`}
                        className="ml-2 flex-1 w-0 truncate text-indigo-600 hover:text-indigo-900"
                      >
                        {lesson.title}
                      </Link>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="font-medium text-gray-600 hover:text-gray-500">
                        {lesson.duration} min
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </dd>
          </div>
          
          {module.quiz && (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Quiz</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Link 
                  to={`/quiz/${courseId}/${module.id}`}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  {module.quiz.title}
                </Link>
              </dd>
            </div>
          )}

          {module.exercises && module.exercises.length > 0 && (
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Exercises</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {module.exercises.map((exercise) => (
                    <li key={exercise.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <Link 
                          to={`/exercise/${courseId}/${module.id}/${exercise.id}`}
                          className="ml-2 flex-1 w-0 truncate text-indigo-600 hover:text-indigo-900"
                        >
                          {exercise.title}
                        </Link>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <span className="font-medium text-gray-600 hover:text-gray-500">
                          Exercise {exercise.order}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          )}

          {module.assessment && (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Assessment</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Link 
                  to={`/assessment/${courseId}/${module.id}`}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  {module.assessment.title}
                </Link>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
};

export default ModuleDetails;
