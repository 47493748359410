import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LessonDetails from '../components/templates/LessonDetails';
import Breadcrumbs, { BreadcrumbItem } from '../components/molecules/Breadcrumbs';
import api from '../api';
import { Course, Module, Lesson } from '../types/types';

const LessonPage: React.FC = () => {
  const { courseId, moduleId, lessonId } = useParams<{
    courseId: string;
    moduleId: string;
    lessonId: string;
  }>();

  const [lesson, setLesson] = useState<Lesson | null>(null);
  const [course, setCourse] = useState<Course | null>(null);
  const [module, setModule] = useState<Module | null>(null);
  const [moduleIndex, setModuleIndex] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (courseId && moduleId && lessonId) {
      setLoading(true);
      api.get<Course>(`/courses/${courseId}`)
        .then((response) => {
          const courseData = response.data;
          setCourse(courseData);
          const moduleData = courseData.modules.find(m => m.id === moduleId);
          if (moduleData) {
            setModule(moduleData);
            setModuleIndex(courseData.modules.indexOf(moduleData));
            const lessonData = moduleData.lessons && moduleData.lessons.find(l => l.id === lessonId);
            if (lessonData) {
              setLesson(lessonData);
            } else {
              setError(`Lesson with ID ${lessonId} not found in module ${moduleId}`);
            }
          } else {
            setError(`Module with ID ${moduleId} not found in course ${courseId}`);
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching course:', error);
          setError(`Failed to load course data. Error: ${error.message}`);
          setLoading(false);
        });
    } else {
      setError('Invalid course, module, or lesson ID.');
      setLoading(false);
    }
  }, [courseId, moduleId, lessonId]);

  const breadcrumbs: BreadcrumbItem[] = [
    { name: 'Courses', href: '/' },
    ...(course ? [{ name: course.title, href: `/course/${courseId}` }] : []),
    ...(module && moduleId !== null ? [{ name: module.moduleName, href: `/module/${courseId}/${moduleId}` }] : []),
    ...(lesson ? [{ name: lesson.title, href: `/course/${courseId}/module/${moduleId}/lesson/${lessonId}` }] : []),
  ];

  return (
    <div className="mx-auto p-4">
      <Breadcrumbs items={breadcrumbs} />
      {loading && <div className="text-center">Loading...</div>}
      {error && <div className="text-red-500 text-center">{error}</div>}
      {!loading && !error && lesson && (
        <>
          <LessonDetails lesson={lesson} />
        </>
      )}
      {!loading && !error && !lesson && <div className="text-center">No lesson data available.</div>}
      <div className="mt-4 text-sm text-gray-500 text-center">
        Debug: Course ID: {courseId}, Module ID: {moduleId}, Lesson ID: {lessonId}, Module Index: {moduleIndex !== null ? moduleIndex : 'Not available'}
      </div>
    </div>
  );
};

export default LessonPage;
