import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ModuleDetails from '../components/templates/ModuleDetails';
import Breadcrumbs, { BreadcrumbItem } from '../components/molecules/Breadcrumbs';
import api from '../api';
import { Module, Course } from '../types/types';


const ModulePage: React.FC = () => {
  const { courseId, moduleId } = useParams<{ courseId: string; moduleId: string }>();
  const [module, setModule] = useState<Module | null>(null);
  const [courseTitle, setCourseTitle] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (courseId && moduleId) {
      setLoading(true);
      api.get<Course>(`/courses/${courseId}`)
        .then((response) => {
          const courseData = response.data;
          setCourseTitle(courseData.title);
          const moduleData = courseData.modules.find(m => m.id === moduleId);
          if (moduleData) {
            setModule(moduleData);
          } else {
            setError(`Module with ID ${moduleId} not found in course "${courseData.title}"`);
          }
        })
        .catch(error => {
          console.error('Error fetching course:', error);
          setError(`Failed to load course data. Error: ${error.message}`);
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      setError(`Invalid course ID (${courseId}) or module ID (${moduleId})`);
      setLoading(false);
    }
  }, [courseId, moduleId]);

  const breadcrumbs: BreadcrumbItem[] = [
    { name: 'Courses', href: '/' },
    { name: courseTitle || 'Course', href: `/course/${courseId}` },
    ...(module ? [{ name: module.moduleName, href: `/module/${courseId}/${moduleId}` }] : []),
  ];

  return (
    <div className="mx-auto p-4">
      <Breadcrumbs items={breadcrumbs} />
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {!loading && !error && module && courseId && (
        <ModuleDetails module={module} courseId={courseId} />
      )}
      {!loading && !error && !module && <div>No module data available.</div>}
      <div className="mt-4 text-sm text-gray-500">
        Debug: Course ID: {courseId || 'Not available'}, Module ID: {moduleId || 'Not available'}
      </div>
    </div>
  );
};

export default ModulePage;