import React from 'react';
import ReactMarkdown from 'react-markdown';

interface LearningObjective {
  objective: string;
  objectiveRequiredItems: string[];
  content: string;
}

interface Lesson {
  id: string;
  title: string;
  order: number;
  version: string;
  duration: number;
  description: string;
  markdown: string;
  learningObjectives: LearningObjective[];
}

interface LessonDetailsProps {
  lesson: Lesson;
}

const LessonDetails: React.FC<LessonDetailsProps> = ({ lesson }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{lesson.title}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Lesson {lesson.order} | Duration: {lesson.duration} minutes</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            <dd className="mt-1 text-sm text-gray-900">{lesson.description}</dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Learning Objectives</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul className="list-disc pl-5 space-y-1">
                {lesson.learningObjectives.map((objective, index) => (
                  <li key={index}>{objective.objective}</li>
                ))}
              </ul>
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Lesson Content</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ReactMarkdown>{lesson.markdown}</ReactMarkdown>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default LessonDetails;
