import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ExerciseDetails from '../components/templates/ExerciseDetails';
import Breadcrumbs, { BreadcrumbItem } from '../components/molecules/Breadcrumbs';
import api from '../api';
import { Course, Module, Exercise } from '../types/types';

const ExercisePage: React.FC = () => {
  const { courseId, moduleId, exerciseId } = useParams<{ courseId: string; moduleId: string; exerciseId: string }>();
  const [exercise, setExercise] = useState<Exercise | null>(null);
  const [course, setCourse] = useState<Course | null>(null);
  const [module, setModule] = useState<Module | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (courseId && moduleId && exerciseId) {
      setLoading(true);
      api.get<Course>(`/courses/${courseId}`)
        .then((response) => {
          const courseData = response.data;
          setCourse(courseData);
          const moduleData = courseData.modules.find((m: Module) => m.id === moduleId);
          if (moduleData) {
            setModule(moduleData);
            const exerciseData = moduleData.exercises && moduleData.exercises.find((e: Exercise) => e.id === exerciseId);
            if (exerciseData) {
              setExercise(exerciseData);
            } else {
              setError(`Exercise ${exerciseId} not found in module ${moduleId}`);
            }
          } else {
            setError(`Module ${moduleId} not found in course ${courseId}`);
          }
        })
        .catch(error => {
          console.error('Error fetching course:', error);
          setError(`Failed to load course data. Error: ${error.message}`);
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      setError('Invalid course, module, or exercise ID.');
      setLoading(false);
    }
  }, [courseId, moduleId, exerciseId]);

  const breadcrumbs: BreadcrumbItem[] = [
    { name: 'Courses', href: '/' },
    ...(course ? [{ name: course.title, href: `/course/${courseId}` }] : []),
    ...(module ? [{ name: module.moduleName, href: `/module/${courseId}/${moduleId}` }] : []),
    ...(exercise ? [{ name: exercise.title, href: `/exercise/${courseId}/${moduleId}/${exerciseId}` }] : []),
  ];

  return (
    <div className="mx-auto p-4">
      <Breadcrumbs items={breadcrumbs} />
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {!loading && !error && exercise && <ExerciseDetails exercise={exercise} />}
      {!loading && !error && !exercise && <div>No exercise data available.</div>}
    </div>
  );
};

export default ExercisePage;
