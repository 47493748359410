import React from 'react';
import { Link } from 'react-router-dom';
import { Course } from '../../types/types';

interface DashboardCardsProps {
  courses: Course[];
}

const DashboardCards: React.FC<DashboardCardsProps> = ({ courses }) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {courses.map((course) => (
        <div key={course.id} className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="w-0 flex-1">
                <h3 className="text-lg font-medium text-gray-900 truncate">{course.title}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {course.modules.length} modules | {course.length} days
                </p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-sm text-gray-500 truncate">{course.objectiveDescription}</p>
            </div>
          </div>
          <div className="bg-gray-50 px-5 py-3">
            <Link
              to={`/course/${course.id}`}
              className="text-sm font-medium text-indigo-600 hover:text-indigo-900"
            >
              View Course
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCards;
