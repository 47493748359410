import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import api from '../../api';

interface Course {
  id: number;
  title: string;
  descriptionObjective: string;
  length: number;
  lessonsPerDay: number;
  modules: any[];
}

interface CreateCourseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCourseCreated: (course: Course) => void;
}

const CreateCourseModal: React.FC<CreateCourseModalProps> = ({ isOpen, onClose, onCourseCreated }) => {
  const [title, setTitle] = useState('');
  const [descriptionObjective, setDescriptionObjective] = useState('');
  const [length, setLength] = useState('');
  const [lessonsPerDay, setLessonsPerDay] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const courseLength = parseInt(length, 10);
    const lessonsPerDayNum = parseInt(lessonsPerDay, 10);

    if (isNaN(courseLength) || isNaN(lessonsPerDayNum)) {
      alert('Please enter valid numbers for course length and lessons per day.');
      return;
    }

    const modules = Array.from({ length: courseLength }, (_, i) => ({
      moduleName: `Module ${i + 1}`,
      lessons: Array.from({ length: lessonsPerDayNum }, (_, j) => ({
        lessonName: `Lesson ${j + 1}`,
        content: ''
      }))
    }));

    try {
      const response = await api.post<Course>('/courses', {
        title,
        descriptionObjective,
        length: courseLength,
        lessonsPerDay: lessonsPerDayNum,
        modules
      });
      onCourseCreated(response.data);
      onClose();
    } catch (error) {
      console.error('Error creating course:', error);
      alert('Failed to create course. Please try again.');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl w-full rounded bg-white p-6">
          <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">Create New Course</Dialog.Title>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">Course Name</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="descriptionObjective" className="block text-sm font-medium text-gray-700">Description/Objective</label>
              <textarea
                id="descriptionObjective"
                value={descriptionObjective}
                onChange={(e) => setDescriptionObjective(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="length" className="block text-sm font-medium text-gray-700">Length (weeks)</label>
              <input
                type="number"
                id="length"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="lessonsPerDay" className="block text-sm font-medium text-gray-700">Lessons per Day</label>
              <input
                type="number"
                id="lessonsPerDay"
                value={lessonsPerDay}
                onChange={(e) => setLessonsPerDay(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={onClose}
                className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                Create
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default CreateCourseModal;
